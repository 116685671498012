import React from 'react'
import fly from '../assets/fly.png'

function Surf() {
    return (
        <div className='surf flex flex-col justify-center items-center lg:min-h-screen lg:pt-[250px] pt-40 overflow-x-clip'>
            <img src={fly} alt="" className='w-[800px] fly moving -mb-20 z-40' />
        </div>
    )
}

export default Surf