import React from 'react'
import memes from '../assets/memes.png'
import kaeruroll from '../assets/kaeruroll.png'
import meme2 from '../assets/meme2.png'
import meme1 from '../assets/meme1.png'
import bamboo from '../assets/bamboo.png'
import meme3 from '../assets/meme3.png'
import meme4 from '../assets/meme4.png'
import meme5 from '../assets/meme5.png'
import meme6 from '../assets/meme6.png'

function Memes() {
    return (
        <div className='text-[#FDFFB5] flex flex-col justify-center items-center '>
            <img src={kaeruroll} alt="" className='w-full' />
            <div className="tripple flex justify-between items-center w-full my-10">
                <img src={bamboo} alt="" className='lg:w-32 w-20' />
                <img src={memes} alt="" className='lg:w-[600px] w-60' />
                <img src={bamboo} alt="" className='lg:w-32 w-20' />
            </div>

            <div className="memeslg hidden lg:block">
                <img src={meme1} alt="" />
                <img src={meme2} alt="" />
            </div>
            <div className="memesmob block lg:hidden">
                <img src={meme3} alt="" className='w-96 rounded-xl' />
                <img src={meme4} alt="" className='w-96 rounded-xl' />
                <img src={meme5} alt="" className='w-96 rounded-xl' />
                <img src={meme6} alt="" className='w-96 rounded-xl' />
            </div>
        </div>
    )
}

export default Memes