import React from 'react'
import howtobuy from '../assets/howtobuy.png'
import contrtact from '../assets/contrtact.png'

function Howto() {
    return (
        <div className='text-[#FDFFB5] flex flex-col justify-center items-center'>
            <img src={howtobuy} alt="" className='lg:w-11/12 p-2' />
            <p className='text-4xl'>Contract Address</p>
            <img src={contrtact} alt="" className='w-8/12' />
        </div>
    )
}

export default Howto