import React from 'react'
import haeruhero1 from '../assets/haeruhero1.svg'
import haeruhero2 from '../assets/haeruhero2.svg'
import haeruhero3 from '../assets/haeruhero3.svg'
import buybtn from '../assets/buybtn.png'
import homebtn from '../assets/homebtn.png'
import telegrambtn from '../assets/telegrambtn.png'
import froghero from '../assets/froghero.png'
import dexscreen from '../assets/dexscreen.png'
import dextools from '../assets/dextools.png'


function Home() {
    return (
        <div className='homebg flex flex-col items-center justify-center relative p-2 lg:pb-96 pb-60 overflow-clip'>
            <div className="header flex justify-between items-center mt-3 w-full lg:px-20 px-3 z-40 ">
                <a href="#"  >
                    <img src={homebtn} alt="" className='w-32 lg:w-40 z-40  ' />
                </a>
                <a href="" target="_blank" rel="noopener noreferrer">
                    <img src={buybtn} alt="" className='w-32 lg:w-40 z-40  ' />
                </a>
            </div>


            <div className="socials flex justify-between items-center w-full lg:w-[450px] z-40 lg:absolute top-40 mt-3 -mb-16">
                <a href="#" target="_blank" rel="noopener noreferrer">
                    <img src={dextools} alt="" className='w-10 lg:w-14 z-40  ' />
                </a>
                <a href="#" target="_blank" rel="noopener noreferrer">
                    <img src={dexscreen} alt="" className='w-10 lg:w-14 z-40  ' />
                </a>

            </div>


            <img src={haeruhero1} alt="" className=' my-10 lg:-my-14 lg:w-11/12 w-12/12' />
            <img src={haeruhero2} alt="" className=' my-0 lg:-my-20 lg:w-9/12 w-12/12 moving' />
            <img src={haeruhero3} alt="" className=' mt-10 lg:-my-14 lg:w-10/12 w-12/12' />

            <img src={froghero} alt="" className='absolute lg:w-[500px] w-72 bobbing' />

            <a href="https://t.me/Kaeru_ERC20" target="_blank" rel="noreferrer" className='w-11/12 lg:w-auto'>
                <img src={telegrambtn} alt="" className=' lg:w-96 z-40 ' />
            </a>
        </div>
    )
}

export default Home