import React from 'react'
import contract from '../assets/contract.png'
import liquidity from '../assets/liquidity.png'
import supply from '../assets/supply.png'
import taxes from '../assets/taxes.png'
import tokenomictext from '../assets/tokenomictext.png'
import smoke from '../assets/smoke.png'

function Tokenomics() {
    return (
        <div className='flex flex-col justify-center items-center dimback relative overflow-clip'>
            <img src={tokenomictext} alt="" className='lg:w-8/12  w-11/12' />

            <div className="toke flex flex-col lg:flex-row justify-between items-center w-full px-4 pb-60 gap-5">
                <img src={contract} alt="" className='w-[350px] ' />
                <img src={taxes} alt="" className='w-[350px] lg:-mb-60' />
                <img src={liquidity} alt="" className='w-[350px]' />
                <img src={supply} alt="" className='w-[350px] lg:-mb-60' />
            </div>


            <img src={smoke} alt="" className='absolute mix-blend-screen bottom-0 lg:max-w-full max-w-none lg:w-auto w-[2000px]' />
        </div>
    )
}

export default Tokenomics