import Home from './components/Home'
import Lore from './components/Lore'
import Surf from './components/Surf';
import Tokenomics from './components/Tokenomics';
import Howto from './components/Howto';
import Memes from './components/Memes';
import Socials from './components/Socials';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Home />
      <Lore />
      <Surf />
      <Tokenomics />
      <Memes />
      <Socials />
      <Footer />
    </div>
  );
}

export default App;
