import React from 'react'
import buy2btn from '../assets/buy2btn.png'
import froggauntlet from '../assets/froggauntlet.jpg'

function Lore() {
    return (
        <div className='flex flex-col items-center justify-center relative waterbg lg:mt-[-330px] mt-[-190px] lg:pb-[200px]'>

            <div className="lg:pt-0 pt-20 lg:mt-0 pb-20 lg:pb-0 lg:mb-[-100px] pic flex lg:flex-row flex-col bg-black/60 lg:w-11/12 lg:rounded-[150px] overflow-clip border-[#FDFFB5] lg:border-2">
                <div className="text flex flex-col lg:w-1/2 justify-center items-center lg:p-10 lg:pr-2 text-[#FDFFB5] ">
                    <h2 className='gasoek text-9xl '>LORE</h2>
                    <p className='text-3xl lg:text-2xl p-3 lg:p-0'>Frogs have been represented for centuries in a variety of cultures as unique symbols, playing important roles in myth, folklore, fairytales, drawings, paintings, and sculptures. In Japan, the frog, (kaeru), is symbolic of fertility and good fortune, and as the word in Japanese means "to return”, frogs can be linked with things/or people returning to their place of origin. They are lucky to keep around, for frogs promise good fortune and friends, (which will at some point come back) so are carried by travelers to make sure they return safely from their journeys. In Japan, they are also given as thoughtful gifts, Omiyage, (a small lucky amulet/toggle type gift), to a friend who is going into a hospital for surgery and thus wishing them a successful return.</p>
                    <a href="#" target="_blank" rel="noopener noreferrer">
                        <img src={buy2btn} alt="" className='lg:w-60 w-72 my-5' />
                    </a>
                </div>

                <div className="img text flex flex-col lg:w-1/2 lg:justify-start lg:items-start justify-center items-center">
                    <img src={froggauntlet} alt="" className='lg:w-[800px] w-96 max-w-none lg:border-l-2 lg:rounded-none rounded-full border-[#FDFFB5]' />
                </div>

            </div>

        </div>
    )
}

export default Lore