import React from 'react'

import hero from '../assets/froghero.png'

function Footer() {
    return (
        <div className='text-[#FDFFB5] flex lg:flex-row flex-col justify-center items-center lg:mt-20 mt-10'>
            <img src={hero} alt="" className='w-32' />
            <p className='gasoek text-2xl lg:text-5xl lg:mt-10 text-center'>ALL RIGHTS RESERVED, 2024</p>
        </div>
    )
}

export default Footer