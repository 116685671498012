import React from 'react'
import roll2 from '../assets/roll2.png'
import twit from '../assets/twit.png'
import tg from '../assets/tg.png'
import socials from '../assets/socials.png'
import bamboooo from '../assets/bamboooo.png'

function Socials() {
    return (
        <div className='flex flex-col justify-center items-center text-[#FDFFB5]'>
            <img src={roll2} alt="" className='w-full' />
            <img src={socials} alt="" className='lg:w-[600px] w-60' />

            <div className="soc flex flex-row gap-14">
                <a href="https://t.me/Kaeru_ERC20" target="_blank" rel="noreferrer">
                    <img src={tg} alt="" className='w-40 lg:w-72' />
                </a>
                <a href="https://x.com/Kaeru_ERC20" target="_blank" rel="noreferrer">
                    <img src={twit} alt="" className='w-40 lg:w-72' />
                </a>
            </div>
            <p className='gasoek text-4xl mt-5'>FOLLOW KAERU</p>

        </div>
    )
}

export default Socials